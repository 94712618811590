"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
exports.wrapTryCatch = function (onCatch) { return function (fn) {
    return function () {
        try {
            return fn.apply(null, arguments);
        }
        catch (e) {
            onCatch && onCatch(e);
        }
    };
}; };
exports.wrapExports = function (onCatch) { return function (o) {
    var wrapper = exports.wrapTryCatch(onCatch);
    return _.reduce(o, function (result, value, key) {
        var _a;
        return (tslib_1.__assign((_a = {}, _a[key] = _.isFunction(value) ? wrapper(value) : value, _a), result));
    }, {});
}; };
