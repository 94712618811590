"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * @class CartIcon
 * @summary An icon that leads users to the shopping cart.
 * @classdesc
 *  The shopping cart icon displays how many items are in the shopping cart.
 *  When users click on the cart icon, a cart sidebar opens on the current page.
 *  You can use a `CartIcon` element to programmitically add items to a user's
 *  cart.
 * @mixes $w.Element
 * @mixes $w.HiddenCollapsedMixin
 * @memberof $w
 * @viewername wysiwyg.viewer.components.tpapps.TPAWidget
 * @tagname cartIcon
 */
/**
 * @typedef {external:Object} $w.CartIcon~AddToCartOptions
 * @summary An object used when adding a product to the shopping cart with options.
 * @property {external:Object} choices Product options to use when adding the
 *  product to the cart. The object contains key:value pairs where the key is the
 *  option name and the value is the chosen option value.
 * @property {$w.CartIcon~AddToCartCustomTextField} customTextField Custom custom
 *  text fields to use when adding the product to the cart.
 * @see [addToCart( )](#addToCart)
 */
/**
 * @typedef {external:Object} $w.CartIcon~AddToCartCustomTextField
 * @summary An object used to pass a custom text field when adding a product to
 *  the shopping cart with options.
 * @property {external:String} title Custom text field title.
 * @property {external:String} value Custom text field value.
 * @see [addToCart( )](#addToCart)
 * @see [AddToCartOptions](#AddToCartOptions)
 */
exports.cartWidgetController = function (_a, compId, context, _b) { return ({
    pageReady: function (_$w) {
        //
    },
    exports: function () { return context.wrapExportsRaven({
        /**
         * @function addToCart
         * @syntax
         * function addToCart(productId: string, [quantity: number], [options: AddToCartOptions]): Promise<void>
         * @summary Adds a product to the shopping cart.
         * @description
         *  The `addToCart()` function returns a Promise that is resolved when the
         *  specified product is added to the shopping cart.
         *
         *  Use the optional `quantity` parameter to add more than one product unit to
         *  the shopping cart at one time. If `quantity` is omitted, one product unit
         *  will be added.
         *
         *  Use the `options` parameter to specify the product options to choose when
         *  adding the product to the cart. For example, if a product comes in different
         *  sizes, you can specify the size that should be added to the cart. If the
         *  product you are adding to the cart has options, you must specify which
         *  options should be chosen.
         *
         *  You can use the [`getProductOptionsAvailability()`](wix-stores-backend.html#getProductOptionsAvailability)
         *  to determine if an item with specific options is available for purchase.
         *
         *  Also use the `options` parameter to specify the values for the product's
         *  custom text fields. If the product you are adding to the cart has mandatory
         *  custom text fields, you must specify values for those fields.
         * @param {external:String} productID The ID of the product to add to the cart.
         * @param {external:Number} [quantity] The number of product units to add to
         *  the cart. If omitted, one product unit will be added.
         * @param {$w.CartIcon~AddToCartOptions} [options] Product options.
         * @returns {Promise}
         * @fulfill {void} When the product has been added to the cart.
         * @snippet [CartIcon-addToCart.es6=Add a product to the cart]
         * @snippet [CartIcon-addToCart_button.es6=Add a product to the cart from a button click]
         * @snippet [CartIcon-addToCart_quantity.es6=Add two units of a product to the cart]
         * @snippet [CartIcon-addToCart_options.es6=Add a product to the cart with options]
         * @memberof $w.CartIcon
         */
        addToCart: function (productId, quantity, options) {
            if (quantity === void 0) { quantity = 1; }
            var params = { productId: productId, quantity: quantity, options: options };
            return new Promise(function (resolve, reject) {
                context.pubSub.publish("Wixcode.AddToCart_" + compId, params);
                context.pubSub.subscribe("Wixcode.AddToCart.Added_" + compId, function (_c) {
                    var data = _c.data;
                    return data.isResolve ? resolve() : reject();
                });
            });
        }
    }); }
}); };
